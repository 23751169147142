import { create } from "zustand"

export interface Filter {
	filter: string
}

type PaginationStore = {
	cursor: number
	limit: number
	next: boolean
	prev: boolean
	sort: string
	filters: Filter[]
	setFilters: (filters: Filter[] | ((prevFilters: Filter[]) => Filter[])) => void
	setSort: (sort: string) => void
	setCursor: (cursor: number) => void
	setLimit: (limit: number) => void
	setNext: (next: boolean) => void
	setPrev: (prev: boolean) => void
}

export const usePaginationStore = create<PaginationStore>(set => ({
	cursor: 0,
	limit: 10,
	next: false,
	prev: false,
	sort: "id,asc",
	filters: [],
	setFilters: filters =>
		set(state => ({
			filters: typeof filters === "function" ? filters(state.filters) : filters,
		})),
	setSort: sort => set({ sort }),
	setCursor: cursor => set({ cursor }),
	setLimit: limit => set({ limit }),
	setNext: next => set({ next }),
	setPrev: prev => set({ prev }),
}))
