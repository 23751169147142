import { create } from "zustand"
import { combine, persist } from "zustand/middleware"

type Auth_Store = {
	animation: boolean
	created_at: number
	email: string
	first_name: string
	lang: string
	last_name: string
	role: string
}

export const useAccountStore = create(
	persist(
		combine(
			{
				account: null as null | Auth_Store,
			},
			set => ({
				setAccount: (account: Auth_Store | null) => set({ account }),
			}),
		),
		{ name: "account" },
	),
)
