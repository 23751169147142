import { useAccountStore } from "@/stores/auth-store"
import { useNavigate } from "react-router-dom"

export const useFetchWithAuth = () => {
	const { setAccount } = useAccountStore()
	const navigate = useNavigate()

	return async (url: string, options = {}) => {
		let response
		let data

		try {
			response = await fetch(url, options)

			if (response.status === 401) {
				console.log("Unauthorized fetch")
				setAccount(null)
				navigate("/login", { replace: true })
				return null
			}

			if (!response.ok) {
				throw new Error(`Fetch failed with status: ${response.status}`)
			}

			data = await response.json()
			//console.log("useFetchWithAuth -> data", data)
		} catch (error) {
			console.error("Fetch or JSON parsing failed:", error)
			throw error // Re-throw the error for further handling if needed
		}

		return data
	}
}
