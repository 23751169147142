import { z } from "zod"

export const passwordSchema = z.object({
	password: z
		.object({
			password: z
				.string()
				.min(8, { message: "Password must be at least 8 characters long" })
				.optional(),
			confirm: z.string().optional(),
		})
		.refine(data => data.password === data.confirm || data.password === "" || data.password === undefined, {
			message: "Passwords don't match!",
			path: ["confirm"], // path of error
		}),
})
