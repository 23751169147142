import { ReactNode } from "react"
import { Navigate } from "react-router-dom"
import { AuthStatus, useAuth } from "@/hooks/API/auth/useAuth"
import { useAccountStore } from "@/stores/auth-store"

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
	const { status } = useAuth()
	const { account } = useAccountStore()
	//console.log("ProtectedRoute -> status", status)

	if (status === AuthStatus.Guest || account === null) {
		//console.log("ProtectedRoute -> Navigate to login")
		return <Navigate to="/login" replace={true} />
	}

	return <>{children}</>
}
