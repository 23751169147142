import { Separator } from "@/components/ui/separator"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { PlusCircleIcon } from "lucide-react"
import { useForm, FormProvider } from "react-hook-form"
import { UserCreateForm } from "@/pages/Settings/Management/UsersTab/UsersForm/UserCreateForm"
import { zodResolver } from "@hookform/resolvers/zod"
import { userCreateFormSchema } from "@/pages/Settings/Management/UsersTab/UsersForm/userCreateFormSchema"
import { toast } from "@/components/ui/use-toast"
import { z } from "zod"
import AccountTable from "@/pages/Settings/Management/UsersTab/UsersTableList/AccountTable"

export type UserCreateFormValues = z.infer<typeof userCreateFormSchema>

export default function UsersTab() {
	const defaultValues: Partial<UserCreateFormValues> = {
		last_name: "",
		first_name: "",
		email: "",
		role: "",
		lang: "fr",
	}

	const methods = useForm({
		resolver: zodResolver(userCreateFormSchema),
		defaultValues,
		mode: "onChange",
	})

	const onSubmit = async (data: Partial<UserCreateFormValues>) => {
		const result = { ...data }
		fetch("/api/user", {
			method: "POST",
			body: JSON.stringify(result),
		})
			.then(res => {
				if (res.ok) {
					toast({
						title: "You submitted the following values:",
						description: (
							<pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
								<code className="text-white">{JSON.stringify(data, null, 2)}</code>
							</pre>
						),
					})
					window.location.reload()
				} else {
					res.json().then(err => {
						for (const [key, value] of Object.entries(err)) {
							methods.setError(key as keyof UserCreateFormValues, {
								type: "onChange",
								message: value as string,
							})
						}
						toast({
							title: "An error occurred",
							description: "Please try again later. " + JSON.stringify(err),
						})
					})
				}
			})
			.catch(err => {
				console.error("Error sending POST request CreateUser:", err)
			})
	}

	return (
		<div className="w-full h-full flex flex-col space-y-8 p-8">
			<div className="flex flex-col sm:flex-row items-center justify-between space-y-2">
				<div className="space-y-1">
					<h2 className="text-2xl font-semibold tracking-tight">List Users</h2>
					<p className="text-sm text-muted-foreground">Manage your users </p>
				</div>
				<div className="ml-auto">
					<FormProvider {...methods}>
						<Dialog>
							<DialogTrigger asChild>
								<Button>
									<PlusCircleIcon className="mr-2 h-4 w-4" />
									Add user
								</Button>
							</DialogTrigger>
							<DialogContent>
								<form onSubmit={methods.handleSubmit(onSubmit)}>
									<DialogHeader>
										<DialogTitle>Add New User</DialogTitle>
										<DialogDescription>Create a new user on your organisation.</DialogDescription>
									</DialogHeader>
									<div className="grid gap-4 py-4">
										<div className="grid gap-2">
											<UserCreateForm />
										</div>
									</div>
									<DialogFooter>
										<Button type="submit">Create</Button>
									</DialogFooter>
								</form>
							</DialogContent>
						</Dialog>
					</FormProvider>
				</div>
			</div>
			<Separator className="my-4" />
			<AccountTable />
		</div>
	)
}
