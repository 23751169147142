import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useAuth } from "@/hooks/API/auth/useAuth"
import { useAccountStore } from "@/stores/auth-store"
import { useAnimationStore } from "@/stores/animationCSS-store"

const LoginForm = () => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [error, setError] = useState<string | null>(null)
	const { login } = useAuth()
	const { account } = useAccountStore()
	const { setAnimationsEnabled } = useAnimationStore()

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			await login(email, password)
			if (account?.animation !== undefined) {
				// Update the global state with the fetched animation preference
				setAnimationsEnabled(account.animation)
			}
		} catch (err) {
			setError("Invalid email or password. Please try again.")
		}
	}

	return (
		<div className="flex justify-center items-center h-screen bg-gray-100">
			<Card className="mx-auto max-w-sm">
				<CardHeader>
					<CardTitle className="text-2xl">Login</CardTitle>
					<CardDescription>Enter your email below to login to your account</CardDescription>
				</CardHeader>
				<CardContent>
					{error && <p className="text-red-500 text-sm mb-4">{error}</p>}
					<form onSubmit={handleSubmit}>
						<div className="grid gap-4">
							<div className="grid gap-2">
								<Label htmlFor="email">Email</Label>
								<Input
									id="email"
									type="email"
									placeholder="m@example.com"
									required
									autoComplete="username"
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</div>
							<div className="grid gap-2">
								<div className="flex items-center">
									<Label htmlFor="password">Password</Label>
									<Link to="/reset_password" className="ml-auto inline-block text-sm underline">
										Forgot your password?
									</Link>
								</div>
								<Input id="password" type="password" required autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} />
							</div>
							<Button type="submit" className="w-full">
								Login
							</Button>
						</div>
					</form>
				</CardContent>
			</Card>
		</div>
	)
}

export default LoginForm
