import React, { useCallback, useEffect, useRef, useState, useMemo } from "react"
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { ColumnHeader } from "@/pages/Settings/Management/UsersTab/UsersTableList/ColumnHeader"
import { DataTableRowActions } from "@/pages/Settings/Management/UsersTab/UsersTableList/RowActions"
import { usePaginationStore } from "@/stores/usersList-store"
import { useTabUsers } from "@/hooks/API/useTabUsers"
import { Checkbox } from "@/components/ui/checkbox"
import { Button } from "@/components/ui/button"
import { formatDate } from "@/lib/utils"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Badge } from "@/components/ui/badge"
import { SquareCheck } from "lucide-react"
import { ButtonAction } from "@/pages/Settings/Management/UsersTab/UsersTableList/ButtonAction"

// TypeScript interfaces for data
interface AccountItem {
	id: number
	email: string
	first_name: string
	last_name: string
	lang: string
	role: string
	animation: boolean
	created_at: number
}

interface Column {
	id: keyof AccountItem
	label: string
	type?: "date"
}

interface ApiResponse {
	items: AccountItem[]
	cursor: number
	has_prev: boolean
	has_next: boolean
}

const newLocker = () => {
	let mutex = Promise.resolve()
	return () =>
		new Promise(resolve => {
			mutex = mutex.then(() => new Promise(resolve))
		})
}

const AccountTable: React.FC = () => {
	const { sort, setSort, cursor, setCursor, next, setNext, prev, setPrev, limit, setLimit, filters } = usePaginationStore()
	const [items, setItems] = useState<AccountItem[]>([])
	const [filtersReady, setFiltersReady] = useState(false)
	const [selectedItems, setSelectedItems] = useState<number[]>([])
	const filtersCleared = useRef(false)

	const { data: columns, error: tabError, isLoading } = useTabUsers()

	const toggleSelectItem = (id: number) => {
		setSelectedItems(prevSelected => (prevSelected.includes(id) ? prevSelected.filter(item => item !== id) : [...prevSelected, id]))
	}

	const toggleSelectAll = () => {
		if (selectedItems.length === items.length) {
			setSelectedItems([])
		} else {
			setSelectedItems(items.map(item => item.id))
		}
	}

	useEffect(() => {
		setSort("id,asc")
		setCursor(0)
	}, [setSort])

	const locker = newLocker()

	const refresh = useCallback(
		async ({ prev = false, next = false }: { prev?: boolean; next?: boolean } = {}) => {
			const unlock = await locker() // Ensure no concurrent calls

			// Ensure that the latest cursor value is used
			const currentCursor = cursor
			const currentLimit = limit
			const currentSort = sort
			const currentFilters = filters

			try {
				if (!prev && !next) {
					setCursor(currentCursor)
					setNext(false)
					setPrev(false)
				}
				filtersCleared.current = false

				const params = new URLSearchParams({
					cursor: currentCursor.toString(),
					prev: prev.toString(),
					next: next.toString(),
					limit: currentLimit.toString(),
					sort: currentSort,
				})

				if (currentFilters.length > 0) {
					currentFilters.forEach(filter => {
						params.append("filter", filter.filter)
					})
				}

				const response = await fetch(`/api/user?${params.toString()}`)

				if (!response.ok) {
					throw new Error(`Failed to fetch: ${response.statusText}`)
				}

				const { items, cursor: newCursor, has_prev: hasPrev, has_next: hasNext }: ApiResponse = await response.json()

				setCursor(newCursor)
				setItems(items || [])
				setPrev(hasPrev)
				setNext(hasNext)
			} catch (error) {
				console.error("Failed to fetch data:", error)
				setItems([])
				setPrev(false)
				setNext(false)
				setCursor(0)
			} finally {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				unlock() // Always unlock after operation
			}
		},
		[cursor, limit, sort, filters, locker, setCursor, setItems, setPrev, setNext],
	)

	// Handler for previous page button
	const handlePrevClick = () => {
		refresh({ prev: true })
		setSelectedItems([])
	}

	// Handler for next page button
	const handleNextClick = () => {
		refresh({ next: true })
		setSelectedItems([])
	}

	// Handler for rows per page selection
	const handleRowsPerPageChange = (value: string) => {
		setLimit(Number(value))
		setSelectedItems([])
	}

	useEffect(() => {
		if (filtersReady) {
			refresh()
		}
	}, [filtersReady, filters, limit, sort])

	useEffect(() => {
		setFiltersReady(true)
	}, [])

	const arrLimits = ["10", "25", "50", "100"]
	const allSelected = useMemo(() => selectedItems.length === items.length, [selectedItems, items])
	const totalSelected = selectedItems.length

	if (isLoading) {
		return <div>Loading...</div>
	}

	if (tabError) {
		return <div>Error loading data</div>
	}

	if (!columns || columns.length === 0) {
		return <div>No columns available</div>
	}

	return (
		<div className="px-4">
			{selectedItems.length > 0 && (
				<Alert className="flex justify-between align-middle p-2 mb-3">
					<div className="flex items-center space-x-2">
						<AlertTitle>
							<Badge className="flex justify-center h-5 w-3">{totalSelected}</Badge>
						</AlertTitle>
						<AlertDescription>{`Vous avez sélectionné ${totalSelected} utilisateur${totalSelected === 1 ? "" : "s"}`}</AlertDescription>
					</div>
					<ButtonAction selectedItems={selectedItems} setSelectedItems={setSelectedItems} refresh={refresh}>
						<SquareCheck className="mr-2 h-4 w-4" />
						Action
					</ButtonAction>
				</Alert>
			)}
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead key="checkbox" dataName="checkbox">
							<ColumnHeader id="checkbox" label="" className="" allSelected={allSelected} toggleSelectAll={toggleSelectAll} />
						</TableHead>
						{columns.columns?.map((column: Column, index: React.Key) => (
							<TableHead key={index} dataName={column.id}>
								<ColumnHeader id={column.id} label={column.label} className={`${index === columns.columns.length - 1 ? "text-right" : ""}`} />
							</TableHead>
						))}
						<TableHead key="tool" dataName="tool">
							<ColumnHeader id="tool" label="" className="text-right" />
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{items.map(item => (
						<TableRow key={item.id}>
							<TableCell key="checkbox" className="p-2">
								<div className="flex items-center space-x-2">
									<Checkbox key={item.id} id={item.id.toString()} checked={selectedItems.includes(item.id)} onCheckedChange={() => toggleSelectItem(item.id)} />
								</div>
							</TableCell>
							{columns.columns.map((column: Column, index: React.Key) => (
								<TableCell key={index} className={index === columns.columns.length - 1 ? "text-right p-2" : "p-2"}>
									{column.type !== "date" ? item[column.id] : formatDate(item[column.id as keyof AccountItem] as number)}
								</TableCell>
							))}
							<TableCell key="tool" className="p-2">
								<DataTableRowActions
									row={{
										id: item.id,
										email: item.email,
										first_name: item.first_name,
										last_name: item.last_name,
										lang: item.lang,
										role: item.role,
										animation: item.animation,
										created_at: item.created_at,
									}}
									selectedItems={selectedItems}
									setSelectedItems={setSelectedItems}
									refresh={refresh}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				{items.length > 0 ? (
					<TableCaption>
						<div className="flex items-center justify-between p-2">
							<div className="flex space-x-2">
								<Select onValueChange={handleRowsPerPageChange} value={limit.toString()}>
									<SelectTrigger className="w-[80px]">
										<SelectValue placeholder="10" />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{arrLimits.map(limit => (
												<SelectItem key={limit} value={limit}>
													{limit}
												</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<div className="flex space-x-2">
								<Button variant="ghost" disabled={!prev} onClick={handlePrevClick} aria-label="Previous page">
									Previous
								</Button>
								<Button variant="ghost" disabled={!next} onClick={handleNextClick} aria-label="Next page">
									Next
								</Button>
							</div>
						</div>
					</TableCaption>
				) : (
					<TableCaption>No data available</TableCaption>
				)}
			</Table>
		</div>
	)
}

export default AccountTable
