import React, { useCallback, useState } from "react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

const ResetForm = () => {
	const [email, setEmail] = useState("")
	const [error, setError] = useState<string | null>(null)
	const [success, setSuccess] = useState<boolean>(false)

	const resetPassword = useCallback(async (email: string): Promise<boolean> => {
		const params = new URLSearchParams({
			email: email.toString(),
		})

		try {
			const res = await fetch(`/api/user/password/reset?${params.toString()}`)

			if (res.ok) return true

			if (res.status === 401) {
				console.log("Invalid email")
				return false
			} else if (res.status === 404) {
				console.log("User not found")
				return false
			} else {
				throw new Error("Reset password failed, please retry later")
			}

		} catch (error) {
			console.error("An error occurred during reset password:", error)
			return false
		} finally {
			console.log("end of reset password")
		}
	}, [])

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			const successReset = await resetPassword(email)

			if (successReset) setSuccess(true)
			else setError("Invalid email")

		} catch (err) {
			setError("Invalid email or password. Please try again.")
		}
	}

	return (
		<div className="flex justify-center items-center h-screen bg-gray-100">
			{
				!success ?
					(<Card className="mx-auto max-w-sm w-full">
						<CardHeader>
							<CardTitle className="text-2xl">Reset Password</CardTitle>
							<CardDescription>Enter your email for reset your password</CardDescription>
						</CardHeader>
						<CardContent>
							{error && <p className="text-red-500 text-sm mb-4">{error}</p>}
							<form onSubmit={handleSubmit}>
								<div className="grid gap-4">
									<div className="grid gap-2">
										<Label htmlFor="email">Email</Label>
										<Input
											id="email"
											type="email"
											placeholder="email@example.com"
											required
											//autoComplete="username"
											value={email}
											onChange={e => setEmail(e.target.value)}
										/>
									</div>
									<Button type="submit" className="w-full">
										Reset password
									</Button>
								</div>
							</form>
						</CardContent>
					</Card>)
					:
					(<Card className="mx-auto max-w-sm w-full">
						<CardHeader>
							<CardTitle className="text-2xl">Email envoyé</CardTitle>
							<CardDescription>Un lien par email vous a été envoyer sur votre email, si votre compte existe</CardDescription>
						</CardHeader>
						<CardContent>
							<div>
								Vérifiez votre boite mail
							</div>
						</CardContent>
					</Card>)
			}
		</div>
	)
}

export default ResetForm
