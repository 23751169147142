// import { Link } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Input } from "@/components/ui/input"

export function UserCreateForm() {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	return (
		<div className="space-y-4">
			<FormField
				control={control}
				name="first_name"
				render={({ field }) => (
					<FormItem>
						<FormLabel>First name</FormLabel>
						<FormControl>
							<Input placeholder="John" {...field} />
						</FormControl>
						{/*<FormDescription>This is your public display name. It can be your real name or a pseudonym. You can only change this once every 30 days.</FormDescription>*/}
						{errors?.first_name?.message && <FormMessage>{errors.first_name.message as string}</FormMessage>}
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="last_name"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Last name</FormLabel>
						<FormControl>
							<Input placeholder="Doe" {...field} />
						</FormControl>
						{/*<FormDescription>This is your public display name. It can be your real name or a pseudonym. You can only change this once every 30 days.</FormDescription>*/}
						{errors?.first_name?.message && <FormMessage>{errors.first_name.message as string}</FormMessage>}
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="email"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Email</FormLabel>
						<FormControl>
							<Input placeholder="john.doe@your-entreprise.com" {...field} />
						</FormControl>
						{errors?.email?.message && <FormMessage>{errors.email.message as string}</FormMessage>}
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="role"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Role</FormLabel>
						<Select onValueChange={field.onChange} defaultValue={field.value}>
							<FormControl>
								<SelectTrigger>
									<SelectValue placeholder="Select a role to attribut" />
								</SelectTrigger>
							</FormControl>
							<SelectContent>
								<SelectItem value="dev">dev</SelectItem>
								<SelectItem value="sale">sale</SelectItem>
								<SelectItem value="guest">guest</SelectItem>
							</SelectContent>
						</Select>
						{/*<FormDescription>*/}
						{/*	You can manage verified email addresses in your <Link to="/examples/forms">email settings</Link>.*/}
						{/*</FormDescription>*/}
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="lang"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Language</FormLabel>
						<Select onValueChange={field.onChange} defaultValue={field.value}>
							<FormControl>
								<SelectTrigger>
									<SelectValue placeholder="Select a language to display" />
								</SelectTrigger>
							</FormControl>
							<SelectContent>
								<SelectItem value="en">EN</SelectItem>
								<SelectItem value="fr">FR</SelectItem>
							</SelectContent>
						</Select>
						<FormMessage />
					</FormItem>
				)}
			/>
		</div>
	)
}
