import useSWR from "swr"
import { useFetchWithAuth } from "@/hooks/API/auth/useFetchWithAuth"

export const useTabUsers = () => {
	//console.log("useTabUsers")
	const fetcherWithAuth = useFetchWithAuth()

	const { data, error, isLoading } = useSWR(`/api/user_schema`, fetcherWithAuth, {
		keepPreviousData: true,
	})

	return {
		data,
		isLoading,
		error,
	}
}
