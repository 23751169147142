import { Link, useLocation, useNavigate } from "react-router-dom"
import { Home, LineChart, Package, Package2, PanelLeft, PieChart, Search, ShoppingCart, Users2 } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList } from "@/components/ui/breadcrumb"
import { Input } from "@/components/ui/input"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { capitalizeFirstLetter } from "@/lib/utils"
import { useAccountStore } from "@/stores/auth-store"
import { useAccountMe } from "@/hooks/API/useAccountMe"
import { useTranslation } from "react-i18next"
import { useCallback, useEffect } from "react"

export const MobileNavPanel = () => {
	const location = useLocation()
	const subPath = location.pathname.split("/")[1]
	const section = capitalizeFirstLetter(subPath)
	const navigate = useNavigate()
	const { setAccount, account } = useAccountStore()
	const { t } = useTranslation(["translation"])
	const { data, error } = useAccountMe()

	useEffect(() => {
		if (error) {
			console.error(error)
		}
	}, [error])

	const handleLogout = useCallback(async () => {
		console.log("Logout")
		const response = await fetch("/api/logout", { method: "GET" })
		if (response.status === 200) {
			setAccount(null)
			navigate("/login")
		}
	}, [setAccount, navigate])

	return (
		<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background p-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
			<Sheet>
				<SheetTrigger asChild>
					<Button size="icon" variant="outline" className="sm:hidden">
						<PanelLeft className="h-5 w-5" />
						<span className="sr-only">Toggle Menu</span>
					</Button>
				</SheetTrigger>
				<SheetContent side="left" className="sm:max-w-xs">
					<nav className="grid gap-6 text-lg font-medium">
						<Link
							to="#"
							className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
						>
							<Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
							<span className="sr-only">Acme Inc</span>
						</Link>
						<Link to="/home" className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground">
							<Home className="h-5 w-5" />
							Home
						</Link>
						<Link to="/dashboard" className="flex items-center gap-4 px-2.5 text-foreground">
							<PieChart className="h-5 w-5" />
							Dashboard
						</Link>
						<Link to="/orders" className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground">
							<ShoppingCart className="h-5 w-5" />
							Orders
						</Link>
						<Link to="/products" className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground">
							<Package className="h-5 w-5" />
							Products
						</Link>
						<Link to="/customers" className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground">
							<Users2 className="h-5 w-5" />
							Customers
						</Link>
						<Link to="/analytics" className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground">
							<LineChart className="h-5 w-5" />
							Analytics
						</Link>
					</nav>
				</SheetContent>
			</Sheet>
			<Breadcrumb className="hidden md:flex">
				<BreadcrumbList>
					<BreadcrumbItem>
						<BreadcrumbLink asChild>
							<Link to={subPath}>{section}</Link>
						</BreadcrumbLink>
					</BreadcrumbItem>
					{/*<BreadcrumbSeparator/>*/}
					{/*<BreadcrumbItem>*/}
					{/*    <BreadcrumbLink asChild>*/}
					{/*        <Link to="#">test</Link>*/}
					{/*    </BreadcrumbLink>*/}
					{/*</BreadcrumbItem>*/}
				</BreadcrumbList>
			</Breadcrumb>
			<div className="relative ml-auto flex-1 md:grow-0">
				<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
				<Input type="search" placeholder={t("translation:placeholder")} className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[320px]" />
			</div>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button id="dropdown-menu" variant="outline" size="icon" className="overflow-hidden rounded-full">
						<img src="/vite.svg" width={36} height={36} alt="Avatar" className="overflow-hidden rounded-full" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end">
					<DropdownMenuLabel>
						<div className="grid gap-4">
							<div className=" flex items-center p-2">
								<div className="flex-1 space-y-1">
									<p className="text-base font-semibold leading-none">
										{(data?.last_name && data?.first_name) || (account?.last_name && account?.first_name)
											? (data?.last_name || account?.last_name) + " " + (data?.first_name || account?.first_name)
											: data?.email || account?.email}
									</p>
									<p className="text-base text-muted-foreground">{data?.role || account?.role}</p>
								</div>
							</div>
						</div>
					</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<DropdownMenuItem onClick={() => navigate("settings/account")}>Settings</DropdownMenuItem>
					<DropdownMenuItem>Support</DropdownMenuItem>
					<DropdownMenuSeparator />
					<DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</header>
	)
}
