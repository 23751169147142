import { create } from "zustand"

type AnimationState = {
	animationsEnabled: boolean
	setAnimationsEnabled: (enabled: boolean) => void
}

export const useAnimationStore = create<AnimationState>(set => ({
	animationsEnabled: true, // Default to true for animations
	setAnimationsEnabled: (enabled: boolean) => set({ animationsEnabled: enabled }),
}))
