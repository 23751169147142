import { useCallback, useEffect, useRef } from "react"
import { useFetchWithAuth } from "@/hooks/API/auth/useFetchWithAuth"
import { useAccountStore } from "@/stores/auth-store"
import { useAnimationStore } from "@/stores/animationCSS-store"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"

export enum AuthStatus {
	Authenticated,
	Guest,
}

export function useAuth() {
	const { account, setAccount } = useAccountStore()
	const { setAnimationsEnabled } = useAnimationStore()
	const navigate = useNavigate()
	const fetcherWithAuth = useFetchWithAuth()

	const { data, error } = useSWR( account ? "/api/user/me" : null, fetcherWithAuth, {
		revalidateOnFocus: true,
		// refreshInterval: 300000, // 5 minutes if you want periodic re-fetching
	})

	const initialMount = useRef(true)

	useEffect(() => {
		if (initialMount.current) {
			initialMount.current = false
			return
		}

		if (data && data !== account) {
			setAccount(data)
		} else if (error && error.message === "Unauthorized") {
			setAccount(null)
		}
	}, [account, data, error, setAccount])

	const status = account ? AuthStatus.Authenticated : AuthStatus.Guest

	const login = useCallback(
		async (email: string, password: string): Promise<boolean> => {
			try {
				const res = await fetch("/api/login", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ email, password }),
				})

				if (!res.ok) {
					if (res.status === 401) {
						console.log("Invalid email or password")
						return false
					} else if (res.status === 404) {
						console.log("User not found")
						return false
					} else {
						throw new Error("Login failed, please retry later")
					}
				}

				const data = await res.json()
				setAccount(data)
				console.log("Login : ", data.animation)
				setAnimationsEnabled(data.animation)
				navigate("/home")
				return true
			} catch (error) {
				console.error("An error occurred during login:", error)
				return false
			} finally {
				console.log("end of login")
			}
		},
		[setAccount, navigate],
	)

	return { status, error, login }
}
