import { z } from "zod"

export const userCreateFormSchema = z.object({
	first_name: z
		.string()
		.min(0, {
			message: "firstname must be at least 2 characters.",
		})
		.max(30, {
			message: "firstname must not be longer than 30 characters.",
		}),
	last_name: z
		.string()
		.min(2, {
			message: "lastname must be at least 2 characters.",
		})
		.max(30, {
			message: "lastname must not be longer than 30 characters.",
		}),
	email: z
		.string({
			required_error: "Please select an email to display.",
		})
		.email(),
	role: z.string(),
	lang: z.string(),
})
