import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Separator } from "@/components/ui/separator"

import UsersTab from "@/pages/Settings/Management/UsersTab/UsersTab"

export default function Management() {
	return (
		<>
			<div className="border-t relative w-full overflow-auto">
				<div className="h-full px-4 py-6 lg:px-8 max-w-full">
					<Tabs defaultValue="users">
						<div className="space-between flex items-start w-fit">
							<TabsList className="w-full">
								<TabsTrigger value="users" className="relative">
									Users
								</TabsTrigger>
								<TabsTrigger value="roles">Roles</TabsTrigger>
								<TabsTrigger value="others">Others</TabsTrigger>
							</TabsList>
						</div>
						<TabsContent value="users" className="flex-1 border-none p-0 outline-none max-w-full overflow-x-auto">
							<div className="overflow-hidden rounded-[0.5rem] border bg-background shadow max-w-full">
								<UsersTab />
							</div>
						</TabsContent>

						<TabsContent value="roles" className="border-none p-0 outline-none max-w-full overflow-x-auto">
							<div className="flex items-center justify-between">
								<div className="space-y-1">
									<h2 className="text-2xl font-semibold tracking-tight">Listen Now</h2>
									<p className="text-sm text-muted-foreground">Top picks for you. Updated daily.</p>
								</div>
							</div>
							<Separator className="my-4" />
						</TabsContent>

						<TabsContent value="others" className="h-full flex-col border-none p-0 data-[state=active]:flex max-w-full overflow-x-auto">
							<div className="flex items-center justify-between">
								<div className="space-y-1">
									<h2 className="text-2xl font-semibold tracking-tight">New Episodes</h2>
									<p className="text-sm text-muted-foreground">Your favorite podcasts. Updated daily.</p>
								</div>
							</div>
							<Separator className="my-4" />
						</TabsContent>
					</Tabs>
				</div>
			</div>
		</>
	)
}
