import useSWR from "swr"
import { useFetchWithAuth } from "@/hooks/API/auth/useFetchWithAuth"

export const useAccountMe = () => {
	const fetcherWithAuth = useFetchWithAuth()

	const { data, error, isLoading } = useSWR("/api/user/me", fetcherWithAuth, {
		keepPreviousData: false,
	})

	return {
		data,
		isLoading,
		error,
	}
}
