import { useState, useEffect } from "react"

export function useWindowSize() {
	const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 640)

	useEffect(() => {
		const handleResize = () => {
			setIsScreenSmall(window.innerWidth < 640)
		}

		window.addEventListener("resize", handleResize)
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	return isScreenSmall
}
