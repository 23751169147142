import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	// DropdownMenuRadioGroup,
	// DropdownMenuRadioItem,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	// DropdownMenuSub,
	// DropdownMenuSubContent,
	// DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import { EllipsisVertical } from "lucide-react"
import { toast } from "@/components/ui/use-toast"
// import { ToastAction } from "@/components/ui/toast"

// const labels = [
// 	{
// 		value: "bug",
// 		label: "Bug",
// 	},
// 	{
// 		value: "feature",
// 		label: "Feature",
// 	},
// 	{
// 		value: "documentation",
// 		label: "Documentation",
// 	},
// ]

interface DataTableRowActionsProps {
	row: {
		id: number
		email: string
		first_name: string
		last_name: string
		lang: string
		role: string
		animation: boolean
		created_at: number
	}
	setSelectedItems: (selectedItems: number[]) => void
	selectedItems: number[]
	refresh: () => void
}

export function DataTableRowActions({ row, refresh, selectedItems, setSelectedItems }: DataTableRowActionsProps) {
	// console.log("DataTableRowActions", row)

	const handleDelete = async () => {
		const confirmed = confirm(`Are you sure you want to delete user ${row.first_name} ${row.last_name}?`)
		if (!confirmed) return

		try {
			const response = await fetch(`/api/user/${row.id}`, {
				method: "DELETE",
			})

			if (!response.ok) {
				if (response.status === 400) {
					console.log("Bad Request: Invalid parameters.")
				} else if (response.status === 401) {
					console.log("Unauthorized: You must be logged in to delete a user.")
				} else if (response.status === 403) {
					console.log("Forbidden: You do not have permission to delete this user.")
				} else if (response.status === 404) {
					console.log("Not Found: The user does not exist.")
				} else {
					console.log(`Error: Failed to delete the user. Status code: ${response.status}`)
				}
				return
			}

			toast({
				title: "User deleted successfully.",
				description: `User ${row.first_name} ${row.last_name}`,
				// action: <ToastAction altText="Goto schedule to undo">Undo</ToastAction>,
			})
			setSelectedItems(selectedItems.filter(id => id !== row.id))
			refresh()
			// Optionally, refresh the user list or remove the deleted user from the UI here
		} catch (error) {
			console.error("Failed to delete user:", error)
			console.log("An unexpected error occurred.")
		}
	}
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted" disabled={selectedItems.length > 0}>
					<EllipsisVertical className="h-4 w-4" />
					<span className="sr-only">Open menu</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[160px]">
				<DropdownMenuItem>Edit</DropdownMenuItem>
				{/*<DropdownMenuItem>Make a copy</DropdownMenuItem>*/}
				<DropdownMenuItem>Favorite</DropdownMenuItem>
				<DropdownMenuSeparator />
				{/*<DropdownMenuSub>*/}
				{/*	<DropdownMenuSubTrigger>Labels</DropdownMenuSubTrigger>*/}
				{/*	<DropdownMenuSubContent>*/}
				{/*		<DropdownMenuRadioGroup value={row.label}>*/}
				{/*			{labels.map(label => (*/}
				{/*				<DropdownMenuRadioItem key={label.value} value={label.value}>*/}
				{/*					{label.label}*/}
				{/*				</DropdownMenuRadioItem>*/}
				{/*			))}*/}
				{/*		</DropdownMenuRadioGroup>*/}
				{/*	</DropdownMenuSubContent>*/}
				{/*</DropdownMenuSub>*/}
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={handleDelete}>
					Delete
					<DropdownMenuShortcut>⌫</DropdownMenuShortcut>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
