import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useState } from "react"

import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { toast } from "@/components/ui/use-toast"

import { passwordSchema } from "@/pages/NewPassword/PasswordSchema"

import { z } from "zod"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useNavigate, useLocation } from "react-router-dom"

type ProfileFormValues = z.infer<typeof passwordSchema>

export default function NewPassword() {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const location = useLocation()
	const navigate = useNavigate()

	const getQueryParams = (search: string | URLSearchParams | string[][] | Record<string, string> | undefined) => {
		return Object.fromEntries(new URLSearchParams(search))
	}
	const { reset_id } = getQueryParams(location.search)

	const defaultValues: Partial<ProfileFormValues> = {
		password: { password: "", confirm: "" },
	}

	const form = useForm<ProfileFormValues>({
		resolver: zodResolver(passwordSchema),
		defaultValues,
		mode: "onChange",
	})
	const passwordValue = form.watch("password.password")

	const onSubmit = async (data: ProfileFormValues) => {
		const result = {
			password: data.password.password,
			reset_id: reset_id,
		}
		try {
			const res = await fetch("/api/user/password", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(result),
			})
			if (res.ok) {
				toast({
					title: "Password updated successfully",
					description: "Mot de passe mis à jour",
				})
				navigate("/login")
			} else {
				const errors = await res.json()
				if (errors.password)
					form.setError("password.password", {
						type: "manual",
						message: errors.password,
					})
			}
		} catch (err) {
			console.error("Error sending PATCH request:", err)
			form.setError("password", {
				type: "manual",
				message: "An unexpected error occurred.",
			})
		}
	}

	return (
		<div className="flex justify-center items-center h-screen bg-gray-100">
			<Card className="mx-auto max-w-sm w-full">
				<CardHeader>
					<CardTitle className="text-2xl">New Password</CardTitle>
				</CardHeader>
				<CardContent>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
							<FormField
								control={form.control}
								name="password.password"
								render={({ field, formState }) => (
									<FormItem>
										<div className="flex items-center space-x-2">
											<FormControl>
												<Input
													type={isPasswordVisible ? "text" : "password"}
													id="password"
													placeholder="Update your password"
													autoComplete="new-password"
													{...field}
												/>
											</FormControl>
											<div className="flex items-center space-x-2">
												<Switch id="password-switch" onCheckedChange={setIsPasswordVisible} />
												<Label htmlFor="password-switch">Show</Label>
											</div>
										</div>
										<FormMessage>{formState.errors?.password?.password?.message}</FormMessage>
									</FormItem>
								)}
							/>

							{passwordValue !== "" && passwordValue !== undefined ? (
								<FormField
									control={form.control}
									name="password.confirm"
									render={({ field, formState }) => (
										<FormItem>
											<div className="flex items-center space-x-2">
												<FormControl>
													<Input type={isPasswordVisible ? "text" : "password"} placeholder="Confirm your Password" {...field} />
												</FormControl>
											</div>
											<FormMessage>{formState.errors?.password?.confirm?.message}</FormMessage>
										</FormItem>
									)}
								/>
							) : null}
							<Button type="submit">Update new password</Button>
						</form>
					</Form>
				</CardContent>
			</Card>
		</div>
	)
}
