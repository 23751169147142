import { ReactNode, useCallback, useEffect, useState } from "react"
import { NavPanel } from "@/components/navPanel/NavPanel"
import { useWindowSize } from "@/hooks/useWindowSize"
import { MobileNavPanel } from "@/components/navPanel/MobileNavPanel"
import { useAnimationStore } from "@/stores/animationCSS-store"

interface MainLayoutProps {
	children: ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
	const [openNav, setOpenNav] = useState(false)
	const isScreenSmall = useWindowSize()
	const animationsEnabled = useAnimationStore(state => state.animationsEnabled)

	useEffect(() => {
		const rules = [...document.styleSheets[0].cssRules]
		rules.forEach(r => {
			if (!(r instanceof CSSStyleRule && r.selectorText === "*")) return
			if (!animationsEnabled) {
				console.log("Animation is false")
				r.style.setProperty("animation-name", "none", "important")
				r.style.setProperty("animation-fill-mode", "none", "important")
				r.style.setProperty("animation-delay", "0s", "important")
				r.style.setProperty("animation-duration", "0s", "important")
				r.style.setProperty("animation-iteration-count", "0", "important")
				r.style.setProperty("transition-property", "none", "important")
				r.style.setProperty("transition-duration", "0s", "important")
				r.style.setProperty("transition-delay", "0s", "important")
			} else {
				console.log("Animation is true")
				r.style.removeProperty("animation-name")
				r.style.removeProperty("animation-fill-mode")
				r.style.removeProperty("animation-delay")
				r.style.removeProperty("animation-duration")
				r.style.removeProperty("animation-iteration-count")
				r.style.removeProperty("transition-property")
				r.style.removeProperty("transition-duration")
				r.style.removeProperty("transition-delay")
			}
		})
	}, [animationsEnabled])

	const renderContent = useCallback(
		() => (
			<div className="flex min-h-[100vh] bg-gray-100 max-w-full">
				{!isScreenSmall && <NavPanel openNav={openNav} setOpenNav={setOpenNav} />}
				<div className={`flex-1 flex-col sm:gap-4 min-h-full overflow-auto w-full ${!isScreenSmall ? (openNav ? "pl-[9rem]" : "pl-[3rem]") : ""}`}>
					<MobileNavPanel />
					{children}
				</div>
			</div>
		),
		[isScreenSmall, openNav, children],
	)
	return renderContent()
}

export default MainLayout
