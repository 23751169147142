// routes.tsx
import { lazy } from "react"
import Account from "../pages/Settings/Account/Account"
import Management from "../pages/Settings/Management/Management"

// Import lazy-loaded components
const HomePage = lazy(() => import("../pages/PageHome"))
const DashboardPage = lazy(() => import("../pages/PageDashboard"))
const OrdersPage = lazy(() => import("../pages/PageOrders"))
const ProductsPage = lazy(() => import("../pages/PageProducts"))
const CustomersPage = lazy(() => import("../pages/PageCustomers"))
const AnalyticsPage = lazy(() => import("../pages/PageAnalytics"))
const SettingsPage = lazy(() => import("../pages/Settings/PageSettings"))

export function routes() {
	return [
		{ path: "/", element: <HomePage />, index: true },
		{ path: "home", element: <HomePage /> },
		{ path: "dashboard", element: <DashboardPage /> },
		{ path: "orders", element: <OrdersPage /> },
		{ path: "products", element: <ProductsPage /> },
		{ path: "customers", element: <CustomersPage /> },
		{ path: "analytics", element: <AnalyticsPage /> },
		{
			path: "settings",
			element: <SettingsPage />,
			children: [
				{
					path: "account",
					element: <Account />,
					role: ["admin", "dev"],
					index: true,
				},
				{
					path: "appearance",
					element: <div>appearance component</div>,
					role: ["admin", "dev"],
				},
				{
					path: "notifications",
					element: <div>notifications component</div>,
					role: ["admin", "dev"],
				},
				{
					path: "management",
					element: <Management />,
					role: ["admin", "dev"],
				},
			],
		},
	]
}
