import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { useCallback } from "react"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatDate(timestamp: number) {
	return new Date(1000 * timestamp).toLocaleDateString()
}

export function debounce<T extends unknown[]>(fn: (...args: T) => void, delay: number) {
	let timeoutId: NodeJS.Timeout
	return function (...args: T) {
		if (timeoutId) clearTimeout(timeoutId)
		timeoutId = setTimeout(() => fn(...args), delay)
	}
}

export function throttle<T extends unknown[]>(fn: (...args: T) => void, limit: number) {
	let lastCall = 0
	return function (...args: T) {
		const now = Date.now()
		if (now - lastCall >= limit) {
			lastCall = now
			fn(...args)
		}
	}
}

// Votre hook personnalisé pour debounce et throttle
export const useDebounceThrottle = (callback: (query: string) => void, debounceDelay: number, throttleLimit: number) => {
	const throttledCallback = useCallback(
		throttle((query: string) => {
			callback(query)
		}, throttleLimit),
		[callback, throttleLimit],
	)

	return useCallback(
		debounce((query: string) => {
			throttledCallback(query)
		}, debounceDelay),
		[debounceDelay, throttledCallback],
	)
}
